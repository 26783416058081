@import "_variables.scss";
.feature-details-container {
  display: flex;
  flex-direction: column;
  margin: 0 var(--chakra-space-21-6);
  row-gap: var(--chakra-space-8);
  .feature-details-close-icon-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    .feature-details-heading-wrapper {
      display: flex;
      align-items: center;
      column-gap: 8px;
      .feature-details-heading {
        font-size: 2rem;
        font-weight: 600;
      }
      .bin-button {
        background-color: transparent;
      } 
    }
  .close-icon {
    cursor: pointer;
    font-size: $font-size-2rem;
  }
  .close-icon:hover {
   color: $color-link-hover-color;
  }
  }

  .feature-details-version-wrapper {
    display: flex;
    align-items: center;
    column-gap: 4rem;
    .feature-details-feature-version-text {
      font-weight: 500;
      font-size: 16px;
    }
  }
  .feature-details-feature-name-container {
    display: flex;
    align-items: center;
    .edit-icon {
      margin-left: 10px;
      :hover {
        cursor: pointer;
      }
    }
  }
  .feature-details-card-container {
    display: flex;
    flex-direction: column;
    row-gap: var(--chakra-lineHeights-4);
    box-shadow: 0px 4px 14px 0px $color-box-shadow;
  }

  .feature-details-wrapper {
    position: relative;
    padding: 2rem;
    border: 1px solid $color-border;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    .feature-details-card-status-icon {
      position: absolute;
      top: 26px;
      left: -8px;
    }
    .link-wrapper {
      width: auto;
      display: flex;
      justify-content: flex-end;
    }
    .link-content-wrapper {
      display: flex;
      justify-content: space-between;
    }
    .feature-details-attribute-container {
      display: flex;
      flex-direction: column;
      row-gap: 3rem;

      .attribute-container {
        display: flex;
        flex-direction: column;
        row-gap: 0.3rem;
      }
      .attribute-questions-container {
        display: flex;
        flex-direction: column;
        row-gap: 0.2rem;
      }
    }
  }

  .feature-details-options-heading-container {
    display: flex;
    flex-direction: column;
    .feature-details-content-heading {
      font-size: 1.3rem;
      font-weight: 500;
      color: $color-font-card;
      text-transform: uppercase;
    }
  }
  .feature-details-secondary-heading {
    font-size: $font-size-small;
    font-weight: $font-weight-medium;
    color: $color-default-font-color;
    text-transform: uppercase;
    font-family: $font-family-sfmono;
  }

  .feature-details-options-container {
    display: flex;
    align-items: center;
    column-gap: 0.5rem;
    flex-wrap: wrap;
    align-items: baseline;
    .feature-details-heading {
      font-size: $font-size-small;
      font-weight: $font-weight-semi-bold;
      color: $color-font-card;
    }
    .feature-details-value {
      font-family: $font-family-regular;
      font-size: $font-size-reg;
      line-height: var(--chakra-lineHeights-10);
      font-weight: $font-weight-regular;
      color: $color-font-card;
      flex: 1;
    }
  }
}
.edit-icon:hover {
  cursor: pointer;
  background-color: $color-sidebar-hover-bg;
  border-radius: $border-radius-small;
  padding-top: var(--chakra-space-1);
  color: $color-surface-text-blue;

}
