@import "_variables.scss";

.topmenu-navlink-active {
  font-weight: bold;
  background-color: $color-nav-hover;
  border-bottom: 4px solid $color-nav-selected;
  color: $color-default-white;
}
.external-flex-items {
  position: sticky;
  top: 0;
  z-index: $zIndexTopNavigation;
  background-color: $color-default-black;
  min-height: $app-header-height;
  width: 100%;
}

//logo
.nav-logo {
  width: 100px;
  padding-left: var(--chakra-space-12);
  align-self: center;
}

//Nav items
.topmenu {
  transition: all 0.3s ease;
  font-size: $font-size-small;
  display: flex;
  align-items: center;
  margin-left: var(--chakra-space-10);
  a:link,
  a:visited {
    color: $color-default-white;
    height: 100%;
    .topmenu-box {
      height: 100%;
      display: flex;
      align-items: center;
      padding: 0px var(--chakra-space-4);
      min-width: 100px;
      text-align: center;
      &:hover {
        @extend .topmenu-navlink-active;
      }
      .topmenu-box-link {
        margin: 0 auto;
        color: $color-default-white;
      }
    }
  }
}

.nav-ham-icon {
  width: 12.5px;
  height: 12.5px;
}

.popovercontent-nav {
  min-width: small;
  padding: var(--chakra-space-4);
  border: var(--chakra-space-0);
}

.user-avatar-menu {
  margin-left: var(--chakra-space-8);
  margin-right: var(--chakra-space-8);
  .chakra-menu__menu-button {
    border-radius: 50%;
    background: transparent;
    .chakra-avatar {
      width: 28px;
      height: 28px;
      border-color: $color-default-white;
      outline: $color-default-white;
      background: $color-default-white !important;
    }
  }

  .menuListContainer {
    max-height: 75vh;
    overflow-y: auto;
  }

  .chakra-menu {
    &__menu-list {
      padding-top: var(--chakra-space-6-4);
      min-width: 190px;
      right: -30px;
      top: 0;
      border-radius: 8px;
      -webkit-box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 0.1);
      -moz-box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 0.1);
      box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 0.1);
    }
    &__menu-button {
      &[data-active],
      &:active,
      &:hover {
        background: none;
      }
    }
    &__menuitem {
      margin: var(--chakra-space-3-2) 0;
      padding: var(--chakra-space-3-2) var(--chakra-space-6-4)
        var(--chakra-space-2-5);
      &.isActive {
        background-color: $color-btn-secondary-hover;
      }
    }

    &__divider {
      border-bottom: 1px solid $color-nav-selected;
      margin: 0 var(--chakra-space-6-4);
    }
    &__group {
      margin-bottom: var(--chakra-space-9-6);
      padding: 0;
      &__title {
        font-family: $font-family-sfmono;
        font-size: $font-size-xs;
        color: $color-btn-link-hover;
        font-size: $font-size-xs;
        line-height: 16px;
        margin: var(--chakra-space-2-5) 0 0;
        padding: 0 var(--chakra-space-6-4) var(--chakra-space-3-2)
          var(--chakra-space-6-4);
        text-transform: uppercase;
      }
    }
  }
}

.menu-item-sign-out {
  margin: 0 0 !important;
}
.menu-item-sign-out:hover {
  background-color: $color-btn-secondary-hover;
}

.menu-item-profile-state:hover {
  background-color: $color-btn-secondary-hover;
}

//search
.search-container {
  display: flex;
  align-items: center;
  margin-left: auto;
  background-color: $color-font-card;
  height: $app-header-height;
  width: 60px;
  &:hover {
    opacity: 0.9;
  }
}

.nav-search-icon {
  &:hover {
    background: none !important;
  }
  svg {
    width: 15px;
    height: 15px;
    color: $color-default-white;
    margin: 0 var(--chakra-space-9);
  }
}

.global-search {
  display: flex;
  border: 1px solid $color-link-hover-color;
  background: $color-default-white;
  position: relative;
  flex-direction: column;
  .search-container-expanded {
    max-width: 700px;
    width: 700px;
    flex-direction: row;
    display: flex;
    height: 61px;
    align-items: center;
    .nav-search-icon {
      height: 100%;
      position: relative;
      svg {
        color: $color-check-background;
        margin: 0 var(--chakra-space-3-5) 0 var(--chakra-space-8);
        z-index: 1;
      }
    }
    .global-search-input {
      width: 100%;
      height: 58px;
      input {
        border-radius: 0;
        background: $color-default-white;
        font-size: $font-size-small;
        line-height: 20px;
        color: $color-default-font-color;
        height: 100%;
        width: 95%;
        border: none;
        padding: 0;
        &:focus {
          outline: none;
          border-color: inherit;
          -webkit-box-shadow: none;
          box-shadow: none;
        }
      }
    }
    .icon-close {
      margin-right: var(--chakra-space-8);
      display: flex;
      align-items: center;
      .tag-icon-styling-close {
        margin-top: 0;
        cursor: pointer;
      }
    }
  }
  .search-results {
    position: absolute;
    top: $app-header-height;
    width: 700px;
    border: 1px solid $color-link-hover-color;
    border-top: none;
    flex-direction: column;
    background: $color-default-white;
    z-index: 10001;
    .no-results {
      color: $color-default-black;
    }
    .result {
      border-bottom: 1px solid $color-border;
      padding: var(--chakra-space-6-4) var(--chakra-space-9-6)
        var(--chakra-space-9-6) var(--chakra-space-9-6);
      gap: 40px;
      justify-content: space-between;
      .title {
        color: $color-link-hover-color;
        line-height: 18px;
        margin-bottom: var(--chakra-space-7-2);
      }
      .description {
        color: $color-font-card;
        line-height: 20px;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        display: -webkit-box;
      }
      .result-module {
        font-family: $font-family-sfmono;
        text-transform: uppercase;
        background-color: $color-sidebar-hover-bg;
        color: $color-link-hover-color;
        border-radius: 20px;
        line-height: 20px;
        padding: var(--chakra-space-2-5) var(--chakra-space-4);
        height: fit-content;
      }
    }
    .btn-view-all-results {
      margin: var(--chakra-space-9-6);
      .app-btn-reg-secondary-transparent {
        width: fit-content;
        letter-spacing: 0.5px;
      }
    }
  }
}

.teaser-links {
  color: $color-default-white;

  a {
    display: inline-block;
    padding: 0 1rem;
  }
  a:link,
  a:visited {
    color: $color-default-white;
  }
  a:hover {
    color: $color-border-hover;
  }
}
