@import "_variables.scss";

.product-launchpad-container {
  padding: var(--chakra-space-12-8);
  padding-top: 0;

  .toast-conatiner {
    width: calc(var(--chakra-sizes-8xl) + var(--chakra-sizes-2));
    margin: 0 auto;
    padding: 0;
    border-radius: var(--chakra-radii-xl);
    border: 1px solid $color-stepper-status-inprogress;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: var(--chakra-space-4);
    .toast-icon {
      width: 7%;
      border-top-left-radius: var(--chakra-radii-xl);
      border-bottom-left-radius: var(--chakra-radii-xl);
      background: $color-stepper-status-inprogress;
      img {
        margin: var(--chakra-space-10) auto;
      }
    }
    .toast-text {
      width: 75%;
      h5 {
        font-size: $font-size-med;
        font-weight: var(--chkara-fontWeights-semibold);
      }
      p {
        font-size: $font-size-reg;
        font-weight: var(--chkara-fontWeights-normal);
        color: $color-font-card;
      }
    }
    .toast-divider {
      border: 1px solid $color-border;
      font-weight: var(--chkara-fontWeights-bold);
    }
    .toast-close {
      border-left: 1px solid $color-border;
      .close-icon {
        width: var(--chakra-sizes-6);
        height: var(--chakra-sizes-6);
        margin: var(--chakra-space-8);
      }
      .close-icon:hover {
        color: $color-link-hover-color;
      }
    }
  }

  .product-lauchpad-catalog {
    width: 90%;
    margin: 0 auto;
  }
}
