@import "_variables.scss";

.status-side-bar-container {
  padding-top: var(--chakra-space-6-4);
  padding-bottom: var(--chakra-space-6-4);
  width: $sidebar-default-width; // CNSL-5753 Todo get from variables.css in shared lib
  max-width: $sidebar-default-width;
  height: 100%;
  box-shadow: $box-shadow-default;
  transition:
    width 0.4s linear,
    box-shadow 0.4s linear;
  overflow-y: auto;
  background-color: $color-default-white;
  h3 {
    font-size: $font-size-reg;
    font-weight: 500;
    margin: 18px 8px;
  }
  .status-side-bar-menu-container {
    &:has(+ :not(.status-side-bar-menu-container)) {
      > div {
        border-bottom-width: 0;
        border-color: transparent;
      }
    }
    .chakra-accordion__item {
      border-color: $color-bg-badge;
      .status-side-bar-menu {
        position: relative;
        border-top: 1px;
        border-bottom: 1px;
        font-size: $font-size-small;
        padding-top: 0px;
        padding-bottom: 0px;
        padding-left: 20px;
        .side-bar-main-menu {
          padding: 14px 16px;
          text-align: left;
        }
        .active-main-menu {
          font-weight: bold;
        }
        .side-bar-main-menu-stepper {
          height: 100%;
          .chakra-divider {
            border-color: $color-divider;
          }
          .side-bar-main-menu-stepper-indicator {
            width: 16px;
            height: 16px;
            .side-bar-stepper-image-inprogress {
              width: 15px;
              height: 12px;
            }
            .side-bar-stepper-image-completed {
              font-size: $font-size-xs;
              vertical-align: super;
            }
          }
        }
      }

      .status-side-bar-menu-panel {
        padding: 0px;
        .status-side-bar-submenu {
          padding: 0rem 20px;
        }
        .side-bar-main-submenu-text {
          font-size: $font-size-xs;
          padding: 8px 16px;
          cursor: pointer;
          display: block;
          text-transform: none;
        }
        .side-bar-main-menu-stepper-indicator {
          width: 12px !important;
          height: 12px !important;
        }
        .side-bar-stepper-image-inprogress {
          width: 10px !important;
          height: 9px !important;
        }
        .side-bar-stepper-image-completed {
          font-size: 9px !important;
          vertical-align: text-top;
        }
      }
      .side-bar-menu-selected {
        background-color: $color-sidebar-seleted-bg;
        font-weight: $font-weight-semi-bold;
      }
      .side-bar-main-menu-stepper-indicator {
        border-radius: 50%;
        border: 1px solid $color-stepper-border;
        background-color: $color-default-white;
        &.side-bar-stepper-status-inprogress {
          background-color: $color-stepper-status-inprogress !important;
          border-color: $color-stepper-status-inprogress !important;
        }
        &.side-bar-stepper-status-completed {
          background-color: $color-toast-success-notification !important;
          border-color: $color-toast-success-notification !important;
        }
      }
    }
  }
  .status-side-bar-menu-item {
    background-color: $color-sidebar-hover-bg;
  }
}
