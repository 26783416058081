@import "_variables.scss";

.product-summary-container {
  .product-info {
    border-bottom: var(--chakra-space-px) solid $color-border;
    margin-top: var(--chakra-space-12);
    padding-bottom: var(--chakra-space-6);
  }
  .product-details-section {
    flex-basis: 40%;
    border-right: calc(var(--chakra-space-px) + var(--chakra-space-px)) solid
      $color-border;
    .add-button {
      width: calc(100% - var(--chakra-space-28));
    }
  }
  .feature-details-container {
    margin-top: calc(var(--chakra-space-px) * -1);
    margin-left: calc(var(--chakra-space-0-8) * -1);
    height: 100%;
    border-radius: 0;
    border: calc(var(--chakra-space-px) + var(--chakra-space-px)) solid
      $color-link-hover-color;
    border-right: none;
    border-bottom: none;
  }
  .edit-image {
    margin-top: var(--chakra-space-1);
  }
  .form-field-message {
    margin-top: var(--chakra-space-4);
    background-color: $color-bg-badge;
    border-radius: $border-radius-small;
    padding-bottom: var(--chakra-space-4-8);
    padding-left: var(--chakra-space-4-8);
    padding-top: var(--chakra-space-0-8);
  }
  .feature-details-continer {
    display: flex;
    height: 100%;
    justify-content: space-between;
    width: 100%;
  }
  .choose-continer {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
  .feature-details-text {
    text-align: center;
    font-size: $font-size-med;
    font-weight: $font-weight-semi-bold;
    width: 60%;
  }
}
