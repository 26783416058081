@import "_variables.scss";

.feature-details-card-container {
  position: relative;
  padding: 2rem;
  border: 1px solid $color-border;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  .feature-details-card-title-container {
    display: flex;
    justify-content: space-between;
    .feature-details-card-title {
      font-weight: $font-weight-medium;
      font-size: $font-size-reg;
      font-family: $font-family-regular;
    }
  }
  .feature-details-card-status-icon {
    position: absolute;
    top: 26px;
    left: -8px;
  }

  .feature-details-options-container {
    display: flex;
    align-items: center;
    column-gap: 0.5rem;
    flex-wrap: wrap;
    align-items: baseline;
    .feature-details-heading {
      font-size: $font-size-small;
      font-weight: $font-weight-semi-bold;
      color: $color-font-card;
    }
    .feature-details-value {
      font-family: $font-family-regular;
      font-size: $font-size-small;
      line-height: var(--chakra-lineHeights-10);
      font-weight: $font-weight-regular;
      color: $color-font-card;
      flex: 1;
    }
  }

  .feature-details-manual-field-container-wrapper {
    display: flex;
    flex-direction: column;
    row-gap: 3rem;
    flex-wrap: wrap;
    align-items: baseline;
    .feature-details-manual-field-container {
      display: flex;
      flex-direction: column;
      row-gap: 1rem;
      align-items: center;
      flex-wrap: wrap;
      align-items: baseline;

      .main-heading {
        font-size: $font-size-reg;
        font-weight: $font-weight-medium;
        color: $color-font-card;
        margin-bottom: var(--chakra-space-4);
        font-family: $font-family-regular;
      }
      .heading-container {
        font-size: $font-size-small;
        font-weight: $font-weight-semi-bold;
        color: $color-font-card;
      }
      .options-wrapper {
        display: flex;
        column-gap: var(--chakra-space-2);
        flex-wrap: wrap;
        align-items: baseline;
        margin-bottom: var(--chakra-space-1-6);
      }
      .value-container {
        flex: 1;
      }
      .sub-heading {
        margin-top: var(--chakra-space-6);
      }
    }
  }
  .chakra-text {
    font-family: $font-family-regular;
    font-size: $font-size-small;
  }
}
.edit-icon {
  width: 25px;
  height: 25px;
}
.edit-icon:hover {
  cursor: pointer;
  background-color: $color-sidebar-hover-bg;
  border-radius: $border-radius-small;
  padding-top: var(--chakra-space-1);
  color: $color-surface-text-blue;
}
