@import "_variables.scss";

.product-wizard {
  margin-bottom: 3rem;
  text-align: center;
  h3 {
    font-size: 2rem;
    font-weight: 600;
  }
  .app-btn-reg-primary {
    display: block;
    margin: 0 auto;
    width: max-content;
  }
}

.catalog-divider {
  margin-bottom: 2rem;
  .chakra-divider {
    border-color: $color-border;
  }
  .catalog-divider-name {
    background-color: $color-bg-default;
    padding: 0px 20px;
  }
}

.configured-products-container {
  .app-card-container {
    &.showProdDetail {
      margin-top: 0px;
    }
  }
  &.product-expectation {
    display: grid;
    grid-template-columns: 60% 40%;
    grid-column-gap: var(--chakra-space-12-8);
    .configured-products-list {
      padding-left: 10px;
    }
    .selected-product-definition {
      overflow-y: auto;
      display: block !important;
      padding: 0 0 0 var(--chakra-space-20);
      border-left: 1px solid $color-border;
    }
    .highlight-product-detail {
      background-color: $color-btn-secondary-hover;
      border-color: $color-btn-secondary-hover;
      .app-btn-link {
        text-decoration: underline;
        text-underline-offset: 7px;
        color: $color-btn-link-hover;
      }
    }
  }

  .selected-product-definition {
    display: none;
  }
  .selected-product-definition-continer {
    border-right: 2px solid $color-border;
    padding-right: var(--chakra-space-6);
    .main-title {
      font-weight: $font-weight-semi-bold;
      margin-left: var(--chakra-space-0-5);
      font-size: $font-size-2rem;
    }
  }
  .close-icon {
    cursor: pointer;
    font-size: $font-size-2rem;
  }
  .close-icon:hover {
    color: $color-link-hover-color;
  }
  .product-category-name {
    font-size: 2rem;
    font-weight: 600;
    border-bottom: 1px solid $color-border;
    background-color: $color-bg-badge;
    padding: var(--chakra-space-4);
    border-radius: 8px 8px 0px 0px;
  }

  .preconfigured-product-resource-links {
    border: 1px solid $color-default-white;
    background: $color-default-white;
    padding: 1rem 0.8rem;
    border-radius: 0.8rem;
    box-shadow: $shadow-box-default;
    margin-bottom: 1.6rem;
  }
  .arrow-continer {
  }
  .arrow-continer :hover {
    background-color: $color-footer-background;
    border-radius: $border-radius-medium;
  }
  .configured-product-btn-container {
    display: block;
    margin-left: auto;
    margin-right: 0px;
  }
}

.configured-product-footer {
  height: $config-form-footer-height;
  background: $color-btn-secondary-hover;
  padding: 1rem 1.5rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.product-catalog-quick-start-modal {
  @media (min-width: 1200px) {
    max-width: max-content !important;
  }
}

.product-catalog-detail-accordion {
  font-size: $font-size-reg;
  .chakra-accordion__item {
    border: 0px;
    .chakra-accordion__button {
      font-size: $font-size-reg;
      padding: 20px 10px 20px 0px;
      border-bottom: 1px solid $color-divider;
    }
    .chakra-accordion__panel {
      padding-left: 0px;
    }
  }
}
.build-another-product-container {
  font-size: $font-size-2rem !important;
  font-weight: 600;
  margin: 40px;
  .build-another-product-link {
    border-top: 1px solid $color-border;
    padding: 24px 12px;
  }
  .app-btn-link {
    font-size: $font-size-2rem !important;
  }
}
.available-title {
  font-weight: $font-weight-semi-bold;
  font-size: $font-size-med;
}
.additional-product-title {
  font-family: $font-family-sfmono-semi-bold !important;
  font-weight: $font-weight-semi-bold;
  text-transform: uppercase;
}
.explore-title {
  font-weight: $font-weight-semi-bold;
  margin-bottom: var(--chakra-space-8);
}
