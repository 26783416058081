@import "_variables.scss";
.my-product-container {
  padding-right: var(--chakra-space-12);
  .my-product-desc-container {
    margin-bottom: var(--chakra-space-12);
    .my-product-desc-title {
      margin-bottom: var(--chakra-space-4);
    }
  }

  .my-product-card {
    .my-product-table {
      thead {
        tr {
          background-color: $color-bg-badge;
          th {
            font-size: $font-size-reg;
            font-weight: var(--chakra-fontWeights-medium);
            text-transform: none;
            color: $color-default-black;
            padding: var(--chakra-space-6) var(--chakra-space-4);
          }
          .custom-table-header-data-item {
            font-size: $font-size-reg !important;
          }
          .custom-table-header-data {
            cursor: pointer;
          }
        }
      }

      tbody {
        .menu-list-container {
          min-width: 9%;
          width: var(--chakra-sizes-40);
          padding: var(--chakra-space-4) var(--chakra-space-2-5);
          max-height: var(--chakra-sizes-64);
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: space-around;
          border-radius: var(--chakra-radii-3xl);
          box-shadow: 0 var(--chakra-space-2) var(--chakra-space-6) 0
            $color-box-shadow;
          button {
            justify-content: left;
            padding: var(--chakra-space-3-2);
            font-size: $font-size-reg;
            font-weight: var(--chakra-fontWeights-bold);
          }
          button:hover {
            background-color: $color-sidebar-hover-bg;
          }
          .style-explore-link {
            width: 100%;
          }
        }

        .my-product-table-description {
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .tag-default {
          width: 136px;
          height: 30px;
          padding: var(--chakra-space-5-6) var(--chakra-space-7-2);
          border-radius: $border-radius-16;
        }

        .warning-count {
          margin-top: var(--chakra-space-2);
          color: $color-border-error;
        }

        .error-count {
          margin-top: var(--chakra-space-2);
          color: $color-border-error;
        }
      }
    }

    .my-product-card-title {
      margin-top: var(--chakra-space-10);
      margin-bottom: var(--chakra-space-6);
    }

    .my-product-card-desc {
      margin-bottom: var(--chakra-space-8);
      font-size: $font-size-reg;
    }
  }
  .app-btn-link {
    &:hover {
      text-decoration: underline;
      text-underline-offset: 8px;
    }
  }
}
